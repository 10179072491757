/**
 * Widget Launcher manage widgets
 * Create widget iframe and handle widget to launcher messaging
 */
import { throttle, merge } from 'lodash'
import Widget from './Widget'
import ssf from '@elliemae/em-ssf-host'
import HostProxy from './HostProxy'
import hash from 'object-hash'
import { Logger } from '../common/Logger'
import {
  EM_DECLARATIVE_WIDGET_STATE_ATTRIBUTE_NAME,
  SCROLL_HANDLER_DEBOUNCE_DURATION,
  EM_EVENT,
  WIDGET_STATE,
  WIDGET_CATALOG,
  AUTH_WIDGET_INSTANCE_ID,
  SSF_HOST_NAME,
  WIDGET_PING_INTERVAL,
  WIDGET_MIN_HEIGHT_BUFFER,
  EM_DECLARATIVE_WIDGET_CLASSNAME
} from '../common/Constants'
import {
  resolveWidgetInstanceId, resolveWindowProperties,
  resolveIFrameProperties, foreach,
  getViewPortDimension, getPropertyByPath, eventFilter
} from '../common/Helper'
/**
 * Class representing Launcher which manages widgets
 * Handles messaging between widgets
 */
class Launcher {
  /**
   * Create launcher
   * @constructor
   * @param {object} options
   * @param {string} options.siteId EllieMae site id
   */
  constructor (options) {
    this.options = Object.assign({}, options)
    this.widgetInstances = {}
    this._setWidgetPingInterval(WIDGET_PING_INTERVAL)
    // setup window listeners
    this._setupWindowEventListeners()

    // Create SSF Host
    this.ssfHost = new ssf.Host(SSF_HOST_NAME, {
      readyStateCallback: (guest) => {
        this._routeMessageToWidget({
          name: EM_EVENT.widgetReady,
          wid: guest.params.wid
        })
      }
    })
    // Create SSF Events
    this.windowPropertyChanged = new ssf.Event()
    this.windowScrolled = new ssf.Event()
    this.windowNavigated = new ssf.Event()
    // Publish proxy to guests
    this.ssfHost.publish(new HostProxy(this._routeMessageToWidget.bind(this)))
  }

  /**
   * Add widget to launcher and create a new widget instance
   * The new widget instance will be managed by launcher
   * @param {object} options
   * @param {function} callback
   * @returns {Widget} widget a Widget instance
   */
  initWidget (options, callback) {
    const baseUrl = getPropertyByPath(WIDGET_CATALOG, `${options.name}.baseUrl`) || options.baseUrl
    const basePath = getPropertyByPath(WIDGET_CATALOG, `${options.name}.basePath`) || options.basePath
    const defaultWidgetOptions = getPropertyByPath(WIDGET_CATALOG, `${options.name}`)
    if (!baseUrl) {
      return null
    }

    const widgetOptions = merge(
      {},
      defaultWidgetOptions,
      options,
      {
        // Widget instance id allows widget to communicates with launcher properly
        wid: options.wid || resolveWidgetInstanceId(hash({ name: options.name }, ...options.config || {})),
        // build widget fullpath
        siteId: this.options.siteId,
        baseUrl,
        basePath,
        extends: options.extends || ''
      }
    )

    if (this.widgetInstances[widgetOptions.wid]) {
      // return existing widget instance
      return this.widgetInstances[widgetOptions.wid]
    }
    // get widget container div
    const widgetInstance = new Widget(widgetOptions, callback)

    // keeping instances of iframes to facilitate communications on page
    this.widgetInstances[widgetInstance.wid] = widgetInstance

    this.renderWidget(widgetInstance, options.hostElement)
    return widgetInstance
  }

  /**
   * render 1 widget
   * @param {*} widgetInstance
   */
  renderWidget (widgetInstance, hostElement) {
    if (widgetInstance) {
      // get widget container div
      let widgetDOMHost
      if (!hostElement) {
        widgetDOMHost = document.querySelector(widgetInstance.selector)
      } else {
        widgetDOMHost = hostElement
      }

      if (widgetDOMHost) {
        // empty host
        widgetDOMHost.innerHTML = ''
        // create widget iframe and attach to dom
        widgetInstance.create(this.ssfHost, widgetDOMHost)

        // update widget state to widget dom host
        const attr = document.createAttribute(EM_DECLARATIVE_WIDGET_STATE_ATTRIBUTE_NAME)
        attr.value = widgetInstance.state
        widgetDOMHost.setAttributeNode(attr)
        this._addWidgetContainersEventListeners({ widgetDOMHost, widgetInstance })
      } else {
        Logger.error(`widget container ${widgetInstance.selector} is not empty`)
      }
    } else {
      Logger.error(`Launcher unable to find widget container ${widgetInstance.selector}`)
    }
  }

  /**
   * Destroy 1 widget instance
   */
  destroyWidget (widgetInstance) {
    if (widgetInstance && this.widgetInstances[widgetInstance.wid]) {
      try {
        Logger.info('Launcher destroy SSF guest', widgetInstance.guest.id)
        this.ssfHost.destroyGuest(widgetInstance.guest.id)
      } catch (e) {
        Logger.error('Launcher unabled to destroy SSF guest', e)
      }
      // get widget container div
      let widgetDOMHost
      if (widgetInstance.selector) {
        widgetDOMHost = document.querySelector(widgetInstance.selector)
      } else {
        widgetDOMHost = widgetInstance.parentRef
      }
      if (widgetDOMHost) {
        widgetDOMHost.innerHTML = ''
      }
      delete this.widgetInstances[widgetInstance.wid]
    } else {
      Logger.warn(`Launcher unable to find widget container ${widgetInstance.selector}`)
    }
  }

  /**
   * Attach widgets to designated widget container on lender's site document
   */
  renderWidgets () {
    foreach(Object.keys(this.widgetInstances), (wid) => {
      // exclude auth widget
      if (wid !== AUTH_WIDGET_INSTANCE_ID) {
        const widgetInstance = this.widgetInstances[wid]
        this.renderWidget(widgetInstance)
      }
    })
  }

  /**
   * this function allows lender send to post message to all managed widgets
   * @param {string} eventName name of the event that sends into all widgets
   * @param {string} message event message that sends into all widgets
   */
  broadcast (eventName, message) {
    this._eachWidget((wigetInstance) => {
      wigetInstance.send(eventName, message)
    })
  }

  /**
   * iterator looping through all widgets and call a callback
   * @private
   */
  _eachWidget (fn) {
    foreach(Object.keys(this.widgetInstances), (wid) => {
      if (typeof fn === 'function' && this.widgetInstances[wid]) {
        fn.call(this, this.widgetInstances[wid])
      }
    })
  }

  _setWidgetPingInterval (interval) {
    this.widgetPingInterval = interval
  }

  _killWidgetPing () {
    Logger.info('Launcher kill widget ping')
    clearTimeout(this.pingTimeout)
  }

  /**
   * setup event listeners to listen to scroll and widget message
   * @private
   */
  _setupWindowEventListeners () {
    // this will ping all widgets and send windows properties every ping interval
    // this is also a backup to communicate widget in slow connection
    const self = this
    function pingWidget (wait) {
      self.pingTimeout = setTimeout(function () {
        Logger.warn('Launcher fallback ping widget in ' + self.widgetPingInterval + 'ms')
        self._sendHostPropertiesToWidget()
        pingWidget(self.widgetPingInterval)
      }, wait)
    }
    pingWidget(this.widgetPingInterval)

    // listen to scroll
    window.addEventListener('scroll', throttle((event) => {
      // broadcast to all widgets when parent window scrolls
      this._sendHostPropertiesToWidget()
      this._sendScrolledToWidget()
    }, SCROLL_HANDLER_DEBOUNCE_DURATION))

    // listen to resize
    window.addEventListener('resize', throttle((event) => {
      // broadcast to all widgets when parent window scrolls
      this._sendHostPropertiesToWidget()
      this._sendWindowResizedToWidget()
    }, SCROLL_HANDLER_DEBOUNCE_DURATION))

    // listed to popstate
    window.addEventListener('popstate', () => {
      this._sendNavigatedToWidget()
    })

    // event listener in parent page
    window.addEventListener('message', (event) => {
      // console.log('==== Launcher received message from Widget ====', event.data); //eslint-disable-line
      const eventObject = eventFilter(event)
      const widgetIframe = document.querySelector('.em-widget > iframe')
      if (!eventObject) {
        return
      }
      if (widgetIframe && event.source === widgetIframe.contentWindow) {
        Logger.debug('Launcher received message from Widget: Event Data: ' + event.data)
      }
      this._routeMessageToWidget(eventObject)
    }, false)

    window.visualViewport.addEventListener('resize', throttle((event) => {
      this._sendHostPropertiesToWidget()
      this._sendVisualViewportResizedToWidget()
    }, SCROLL_HANDLER_DEBOUNCE_DURATION))

    const setResizeObserver = () => {
      if (typeof ResizeObserver !== 'undefined') {
        window.resizeObserver = new ResizeObserver(throttle(() => {
          this._sendHostPropertiesToWidget()
          this._sendBodyResizedToWidget()
        }, SCROLL_HANDLER_DEBOUNCE_DURATION))
      }
    }
    setResizeObserver()
  }

  /**
   * Redirect message from widget iframe to the coresponding widget callback
   * When a message is emitted from widget
   * this function will distribute the mesage to the widget's instance listener function
   * @private
   * @param {MessageEvent} event
   */
  _routeMessageToWidget (eventObject) {
    if (eventObject.name) {
      const widgetInstance = this.widgetInstances[eventObject.wid]
      if (widgetInstance) {
        switch (eventObject.name) {
          case EM_EVENT.widgetConnected:
            Logger.info('Widget Connected received')
            widgetInstance.state = WIDGET_STATE.CONNECTED
            this._updateWidgetStateToHostElement(widgetInstance)
            this._sendHostPropertiesToWidget(widgetInstance)
            this._sendStorageToWidget(widgetInstance)
            break
          case EM_EVENT.widgetReady:
            widgetInstance.state = WIDGET_STATE.READY
            // set ping interview to longer time after connected
            Logger.info('Widget Ready received, Launcher stop widget ping')
            this._killWidgetPing()
            this._updateWidgetStateToHostElement(widgetInstance)
            break
          case EM_EVENT.widgetStateChanged:
            widgetInstance.state = WIDGET_STATE.widgetStateChanged
            widgetInstance.setInitUrlParams(eventObject.message.state, eventObject.message.forceResume)
            break
          case EM_EVENT.widgetResized: {
            // #if process.env.CUSTOM_IFRAME_RESIZER_ENABLED
//             const newHeight = getPropertyByPath(eventObject, 'message.height')
//             if (newHeight) {
//               widgetInstance.setHeight(newHeight)
//             }
            // #endif
            break
          }
          // Docusign Temporarily fix to make sure the iframe height fix the view port height
          // So that the next or continue button from Docusign page will show within the view port
          case 'DOCUSIGN_NAVIGATE': {
            this._docusignNavigateHandler(eventObject, widgetInstance)
            break
          }
          case EM_EVENT.widgetAuthTimerStart: {
            const delay = parseInt(getPropertyByPath(eventObject, 'message.timeout'), 10)
            const authErrorUrl = getPropertyByPath(eventObject, 'message.authErrorUrl')
            widgetInstance.startAuthTimer(delay, authErrorUrl)
            break
          }
          case EM_EVENT.widgetAuthTimerEnd:
            widgetInstance.stopAuthTimer()
            break
          case EM_EVENT.widgetSaveData: {
            const appData = getPropertyByPath(eventObject, 'message.data')
            widgetInstance.saveAppData(appData)
            break
          }
          default:
            // do nothing
        }
      } else {
        // handle this message from EllieMae Login Page
        // this will stop timer in child widgets if exist
        if (eventObject.name === EM_EVENT.widgetAuthTimerEnd) {
          this._eachWidget((widgetInstance) => {
            widgetInstance.stopAuthTimer()
          })
        }
        // Handle this message from EllieMae IDP Login Page
        // This will send app data from session storage of widget window to idp login page
        if (eventObject.name === EM_EVENT.appDataRequestFromIdp2Widget) {
          this._eachWidget((widgetInstance) => {
            widgetInstance.sendAppData()
          })
        }
      }
    }
  }

  /**
   * method to notify parent window scroll event is raised
   * @private
   */
  _sendScrolledToWidget () {
    const winProp = resolveWindowProperties()
    ssf.raiseEvent('HostWindow', 'scrolled', winProp)
  }

  /**
   * method to notify parent window popstate event is raised
   */
  _sendNavigatedToWidget () {
    const winProp = resolveWindowProperties()
    ssf.raiseEvent('HostWindow', 'navigated', winProp)
  }

  /**
   * method to report predetermined window properties to widget instance
   * @private
   */
  _sendHostPropertiesToWidget (widgetInstance) {
    const winProp = resolveWindowProperties()
    ssf.raiseEvent('HostWindow', 'windowPropertyChanged', winProp)

    const sendPropertiesPerWidget = (widgetInstance) => {
      widgetInstance.send(EM_EVENT.widgetIFramePropertiesUpdate,
        resolveIFrameProperties(widgetInstance.guest.domElement))
      widgetInstance.send(EM_EVENT.launcher2Widget, widgetInstance.meta)
    }

    if (widgetInstance) {
      sendPropertiesPerWidget(widgetInstance)
    } else {
      this._eachWidget((widgetInstance) => {
        sendPropertiesPerWidget(widgetInstance)
      })
    }
  }

  _sendVisualViewportResizedToWidget () {
    const winProp = resolveWindowProperties()
    ssf.raiseEvent('HostWindow', 'visualViewportResized', winProp)
  }

  _sendBodyResizedToWidget () {
    const winProp = resolveWindowProperties()
    ssf.raiseEvent('HostWindow', 'bodyResized', winProp)
  }

  _sendWindowResizedToWidget () {
    const winProp = resolveWindowProperties()
    ssf.raiseEvent('HostWindow', 'windowResized', winProp)
  }

  /**
   * method reflect widget state to the containing element
   * @private
   * @param {object} widgetInstance
   */
  _updateWidgetStateToHostElement (widgetInstance) {
    if (widgetInstance.parentRef) {
      widgetInstance.parentRef.setAttribute(`${EM_DECLARATIVE_WIDGET_STATE_ATTRIBUTE_NAME}`,
        widgetInstance.state)
    }
  }

  _sendStorageToWidget (widgetInstance) {
    if (widgetInstance) {
      widgetInstance.send(EM_EVENT.sendStoredDataToWidget, Object.assign({}, widgetInstance.getStoredData()))
    }
  }

  _docusignNavigateHandler (eventObject, widgetInstance) {
    try {
      const DOCUSIGN_MOBILE_BREAK_POINT = 768 // docusign page mobile breakpoint
      const DOCUSIGN_LOAD_DELAY_MS = 4000 // ms
      const viewPort = getViewPortDimension()
      /**
       * add some buffer so the new iframe is slight small then view port
       */
      const newFrameHeight = viewPort.height - WIDGET_MIN_HEIGHT_BUFFER
      /**
       * This is a temp fix
       * Only adjust the iframe height if
       * the width of the page is small than Docusign Mobile break point
       * and if the iframe height is taller than the view port
       */
      const curFrameHeight = getPropertyByPath(widgetInstance, 'guest.domElement.offsetHeight')
      if (!curFrameHeight) {
        console.error('Unable to determine iframe height')
        return
      }
      if (viewPort.width < DOCUSIGN_MOBILE_BREAK_POINT &&
        curFrameHeight > newFrameHeight) {
        setTimeout(() => {
          widgetInstance.setHeight(newFrameHeight)
        }, DOCUSIGN_LOAD_DELAY_MS)
      }
    } catch (e) {
      console.error(e)
    }
  }

  /**
   * method to add listeners into the widgets containers
   * @private
   * @param {object} widgetDOMHost
   * @param {object} widgetInstance
   */
  _addWidgetContainersEventListeners ({ widgetDOMHost, widgetInstance }) {
    const containerElement = document.getElementById(widgetInstance.containerId)
    const scrollCallback = throttle((event) => {
      // broadcast to widgetInstance when element scroll
      this._sendHostPropertiesToWidget(widgetInstance)
      this._sendScrolledToWidget()
    }, SCROLL_HANDLER_DEBOUNCE_DURATION)

    const resizeCallback = throttle((event) => {
      // broadcast to widgetInstance when element resize
      this._sendHostPropertiesToWidget(widgetInstance)
      this._sendWindowResizedToWidget()
    }, SCROLL_HANDLER_DEBOUNCE_DURATION)

    if (widgetDOMHost) {
      widgetDOMHost.addEventListener('scroll', scrollCallback)
      widgetDOMHost.addEventListener('resize', resizeCallback)
    }
    const isContainerSameAsWidget = containerElement?.classList?.contains(EM_DECLARATIVE_WIDGET_CLASSNAME)
    if (containerElement && !isContainerSameAsWidget) {
      containerElement.addEventListener('scroll', scrollCallback)
      containerElement.addEventListener('resize', resizeCallback)
    }

    const setResizeObserver = () => {
      if (typeof ResizeObserver !== 'undefined') {
        if (widgetDOMHost) {
          widgetDOMHost.resizeObserver = new ResizeObserver(resizeCallback)
        }
        if (containerElement && !isContainerSameAsWidget) {
          containerElement.resizeObserver = new ResizeObserver(resizeCallback)
        }
      }
    }
    setResizeObserver()
  }
}

export default Launcher
